<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/vehicles">Fahrzeuge</router-link></li>
              <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">{{ vehicle.license_plate }}</router-link></li>
              <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/damages'">Schäden</router-link></li>
              <li>Neu</li>
            </ul>
            <div class="header-actions">
            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">Stammdaten</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/reservations'">Reservierungen</router-link></li>
            <li class="active"><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/damages'">Schäden</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/repairs'">Reparaturen</router-link></li>
            <!--<li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/insurance'">Versicherung</router-link></li>-->
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/documents'">Dokumente</router-link></li>
          </ul>

          <div class="admin-body">

            <form @submit="create_damage" class="form" method="post">

              <div style="margin: 50px 0 20px 0">
                <div style="position:relative;width:100%;height:530px">
                  <div v-if="vehicle.group_id == 1" class="car-wrap">
                    <p><img src="@/assets/handover_car.jpg" width="100%" height="auto"></p>
                  </div>

                  <div v-if="vehicle.group_id == 2" class="car-wrap">
                    <p><img src="@/assets/handover_transporter.jpg" width="100%" height="auto"></p>
                  </div>

                  <VueSignaturePad width="100%" height="530px" :options="options" ref="signaturePad" style="position:absolute;left:0;top:0" />
                </div>
              </div>

              <div class="form-wrap select-wrap">
                <label for="street_number">Verursacher</label>
                <select v-model="vehicle_damage.user_id" class="select-wrap">
                  <option v-for="user in customers" v-bind:value="user.id" v-bind:key="user.id">
                    {{ user.last_name }}, {{ user.first_name }} ({{ user.email }})
                  </option>
                </select>
              </div>

              <div class="form-wrap select-wrap">
                <label for="reservation">Reservierung</label>
                <select v-model="vehicle_damage.reservation_id" class="select-wrap">
                  <option v-for="reservation in reservations" v-bind:value="reservation.id" v-bind:key="reservation.id">
                    {{ reservation.id }} | {{ reservation.pickup_date }} bis {{ reservation.return_date }} von {{ reservation.first_name }} {{ reservation.last_name }} (Fahrzeug: {{ reservation.category.name }} <span v-if="reservation.vehicle">– {{ reservation.vehicle.license_plate }}</span>)
                  </option>
                </select>
              </div>

              <div class="form-wrap">
                <label for="description">Beschreibung</label>
                <textarea v-model="vehicle_damage.description" rows="5" class="form-input" type="text" name="description" id="description" placeholder="Art des Schadens..."></textarea>
              </div>

              <div class="form-wrap">
                <label for="damage_number">Schadensnummer</label>
                <input v-model="vehicle_damage.damage_number" class="form-input" type="text" name="damage_number" id="damage_number">
              </div>

              <div class="form-wrap">
                <label for="dhl_number">DHL Schadensnummer</label>
                <input v-model="vehicle_damage.dhl_number" class="form-input" type="text" name="dhl_number" id="dhl_number">
              </div>

              <div class="row row-gutter-20">
                <div class="col-8">
                  <div class="form-wrap">
                    <label for="amount_estimate">Kostenvoranschlag</label>
                    <input v-model="vehicle_damage.amount_estimate" class="form-input" type="text" name="amount_estimate" id="amount_estimate" placeholder="0.0">
                  </div>
                </div>
                <div class="col-8">
                  <div class="form-wrap">
                    <label for="amount_deposit">Kaution</label>
                    <input v-model="vehicle_damage.amount_deposit" class="form-input" type="text" name="amount_deposit" id="amount_deposit" placeholder="0.0">
                  </div>
                </div>
                <div class="col-8">
                  <div class="form-wrap">
                    <label for="damage_sum">Zu zahlender Betrag</label>
                    <input v-model="vehicle_damage.damage_sum" class="form-input" type="text" name="damage_sum" id="damage_sum" placeholder="0.0">
                  </div>
                </div>
              </div>

              <p>
                <input type="submit" value="Speichern ›" class="button button-orange button-small">
              </p>

            </form>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../../components/admin_nav'

export default {
  name: 'new_admin_vehicle_repair',
  components: {
    AdminNav
  },
  data () {
    return {
      customers: [],
      reservations: [],
      vehicle: {},
      vehicle_damage: {
        reservation_id: "",
        description: "",
        dhl_number: "",
        damage_sum: "",
        damage_image: ""
      },
      temp_image: "",
      options: {
        penColor: 'red',
        minWidth: 10,
        maxWidth: 10
      }
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.vehicle = response.data.vehicle;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/customers', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.customers = response.data.customers;
      })
      .catch(error => {
        console.log(error);
      })

      this.get_reservations('/v1/admin/reservations?page=1');

    },
    get_reservations(page) {
      axios.get(process.env.VUE_APP_BASE_API+page, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        response.data.reservations.forEach(reservation => {
          this.reservations.push(reservation);
        })
        if (response.data.meta.pagination.links.next) {
          this.get_reservations(response.data.meta.pagination.links.next);
        }
      })
    },
    create_damage: function (e) {
      e.preventDefault();

      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$notify({
          group: 'alert',
          type: 'error',
          text: "Bitte zeichnen Sie den Schaden ein."
        });
      } else {

        this.vehicle_damage.damage_image = data;

        axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id+'/damages', this.vehicle_damage, { headers: { Authorization: this.$store.getters.getToken }})
        .then(() => {
          this.$router.push('/admin/vehicles/'+this.$route.params.id+'/damages');
        })
        .catch(error => {
          error.response.data.errors.forEach(val => {
            this.$notify({
              group: 'alert',
              type: 'error',
              title: val,
            });
          })
        })

      }

    },
    set_image(event) {
      this.temp_image = event.target.files[0];
    },
    clear_signature() {
      this.$refs.signaturePad.clearSignature();
    },
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

</style>
